import { useContext, useEffect, useState } from "react";
import "./style.css";
import { AutoDelete, NoteAlt, MoreVert } from "@mui/icons-material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import {
  creatingEventContext,
  isBookingFormOpenContext,
  allResourcesContext,
  updatingEventContext,
  viewOnlyFormContext,
  bookingHistoryContext,
  openCancelConfirmContext,
} from "../context/ContextProvider";
import MyMenu from "./common/MyMenu";
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const HistoryCard = (props) => {
  const [isBookingFormOpen, setIsBookingFormOpen] = useContext(isBookingFormOpenContext);
  const [, setUpdatingEvent] = useContext(updatingEventContext);
  const [, setCreatingEvent] = useContext(creatingEventContext);
  const [bookingHistory] = useContext(bookingHistoryContext);
  const [, setViewOnlyForm] = useContext(viewOnlyFormContext);
  const [allResources] = useContext(allResourcesContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [eventToRebook, setEventToRebook] = useState(props.event);
  const [, setOpenCancelConfirm] = useContext(openCancelConfirmContext);
  useEffect(() => {
    setEventToRebook(props.event);
  }, [bookingHistory]);
  return (
    <div
      className="p-2 d-flex justify-content-between"
      style={
        props.upcoming
          ? {
              background: "rgba(255, 255, 255, 0.13)",
              borderRadius: "15px",
              boxShadow: "0px 0.5px 0px rgba(0, 0, 0, 0.25)",
            }
          : { borderBottom: "0.5px solid rgba(0, 0, 0, 0.09)" }
      }
    >
      <div
        className="d-flex"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setCreatingEvent(props.event);
          setIsBookingFormOpen(!isBookingFormOpen);
        }}
      >
        <div className="flex-fill ms-1">
          <label
            className="small"
            style={{
              cursor: "pointer",
              fontWeight: 500,
              fontSize: "0.8rem",
              fontStyle: "normal",
              color: props.upcoming ? "#FFFFFF" : "#000000",
            }}
          >
            {props.event.title}
          </label>
          <div
            style={{
              fontWeight: 450,
              fontSize: "0.75rem",
              fontStyle: "normal",
              color: props.upcoming ? "#D7D7D7" : "#818181",
            }}
          >
            <label style={{ cursor: "pointer" }}>
              {new Date(props.event.start).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }) +
                " - " +
                new Date(props.event.end).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }) +
                `  |  `}
              {new Date(props.event.startDate).toDateString().slice(0, -4)}
            </label>
            <br />
            <label style={{ cursor: "pointer" }}>
              {allResources.length &&
                allResources
                  ?.filter((resource) => resource._id === props.event.resourceId)
                  .map((resource) => resource.roomName)}
            </label>
          </div>
        </div>
      </div>

      <Tooltip placement="bottom-end" title="Options">
        <MoreVert
          variant="contained"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className="mt-1"
          fontSize="13px"
          sx={{
            color: props.upcoming ? "#FFFFFF" : "#818181",
            cursor: "pointer",
          }}
        />
      </Tooltip>

      <MyMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {props.isEditable
          ? [
              <MenuItem
                key={props.event._id + "1"}
                onClose={() => setAnchorEl(null)}
                onClick={() => {
                  setAnchorEl(null);
                  setOpenCancelConfirm(`${props?.event._id}$${props?.event?.title}`);
                }}
                sx={{ fontSize: 15 }}
              >
                <AutoDelete
                  sx={{
                    fontSize: 20,
                  }}
                />
                &nbsp;Cancel
              </MenuItem>,
              <MenuItem
                key={props.event._id + "2"}
                onClose={() => setAnchorEl(null)}
                onClick={() => {
                  setAnchorEl(null);
                  setViewOnlyForm(true);
                  setUpdatingEvent(props.event);
                  setIsBookingFormOpen(!isBookingFormOpen);
                }}
                sx={{ fontSize: 15 }}
              >
                <NoteAlt
                  sx={{
                    fontSize: 20,
                  }}
                />
                &nbsp;Update
              </MenuItem>,
            ]
          : [
              <MenuItem
                key={props.event._id}
                onClose={() => setAnchorEl(null)}
                sx={{ fontSize: 15 }}
                onClick={() => {
                  setAnchorEl(null);

                  // Gives Current Time with Date 01/01/19770
                  const startTime = new Date();
                  startTime.setFullYear(1970);
                  startTime.setMonth(0);
                  startTime.setDate(1);
                  const endTime = new Date(startTime);
                  setCreatingEvent({
                    ...eventToRebook,
                    startDate: new Date(new Date().toDateString()),
                    endDate: new Date(new Date().toDateString()),
                    start: startTime,
                    end: endTime.setMinutes(endTime.getMinutes() + 30),
                  });
                  setViewOnlyForm(true);
                  setIsBookingFormOpen(!isBookingFormOpen);
                }}
              >
                <EventRepeatIcon
                  sx={{
                    fontSize: 20,
                  }}
                />
                &nbsp;Rebook
              </MenuItem>,
            ]}
      </MyMenu>
    </div>
  );
};

export default HistoryCard;
