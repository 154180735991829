import { React, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import logo from "../../assets/GatewayGroup.png";
import otpIcon from "../../assets/password.png";
import "./Login.css";
import { currentUserContext } from "../../context/ContextProvider";
import {
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
  CircularProgress,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [, setCurrentUser] = useContext(currentUserContext);

  const [showPassword, setShowPassword] = useState(false);

  // Validate OTP
  const validateUser = (e) => {
    setLoading(true);
    e.preventDefault();
    if (!username.length) {
      setError("Please enter a username!");
      document.getElementById("username").focus();
      setLoading(false);
    } else if (!password.length) {
      setError("Please enter a password!");
      document.getElementById("password").focus();
      setLoading(false);
    } else {
      setError("");
      axios
        .post(`${process.env.REACT_APP_API_URL}/validateUser`, {
          password: password,
          username: username,
        })
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("currentUser", JSON.stringify(res.data.data));
            setCurrentUser(res.data.data);
            setError("");
            navigate("/");
            setLoading(false);
            toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT });
          } else {
            setLoading(false);
            toast.error(res.data.message, { position: toast.POSITION.TOP_RIGHT });
            setError(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
          console.log(err);
        });
    }
  };

  const screen = () => {
    return (
      <div id="login">
        <p>
          <img className="img-fluid" src={otpIcon} alt="Login" height="100rem" width="100rem" />
        </p>
        <p className="text-muted">
          Enter your GIS login details <br />
        </p>
        <form onSubmit={(e) => validateUser(e)}>
          <div id="otp-screen" className="form-border d-flex flex-column align-items-center">
            <TextField
              type="text"
              className="col-7"
              label="Username"
              name="username"
              id="username"
              variant="filled"
              size="small"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormControl className="col-7 mt-3" size="small" variant="filled">
              <InputLabel>Password</InputLabel>
              <FilledInput
                size="small"
                label="Password"
                name="password"
                id="password"
                onBlur={() => setShowPassword(false)}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button
              endIcon={loading && <CircularProgress size="1.3rem" />}
              className="text-capitalize col-7 mt-4 mb-3"
              type="submit"
              disabled={loading}
              style={{ backgroundColor: "#ffc107", color: "inherit" }}
            >
              SIGN IN
            </Button>
            <p id="error" style={{ color: "red" }}>
              {error}
            </p>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="d-flex" style={{ height: "100%" }}>
      {/* <!-- Login Form ========================= --> */}
      <div
        id="a"
        style={{ minWidth: "30%" }}
        className={" d-flex flex-column justify-content-center bg-light shadow-lg transition duration-1000"}
      >
        <div className="container text-center">
          <div className="logo mb-4 align-items-start">
            <img src={logo} alt="CRB" height="80" />
            <h5 className="my-3" style={{ letterSpacing: "1px" }}>
              <b>Conference Room Booking</b>
            </h5>
          </div>
          {screen()}
        </div>
      </div>
      {/* <!-- Login Form End --> */}

      {/* <!-- Welcome Text ========================= --> */}
      <div id="b" style={{ width: "70%", overflow: "hidden" }}>
        <div className="bgi g-0 text-center py-5 hover-zoom" style={{ height: "100%" }}>
          {/* <p className="text-6 d-inline-block fw-500">Welcome back!</p>
          <h1 className="text-12 fw-600 mb-2">You can book Your Place here.</h1> */}
        </div>
      </div>
      {/* <!-- Welcome Text End --> */}
    </div>
  );
}

export default Login;
