import React, { createContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
export const locationsContext = createContext();
export const currentLocationContext = createContext();
export const isBookingFormOpenContext = createContext();
export const allUserDataContext = createContext();
export const bookingHistoryContext = createContext();
export const creatingEventContext = createContext();
export const updatingEventContext = createContext();
export const currentUserContext = createContext();
export const myResourcesContext = createContext();
export const allResourcesContext = createContext();
export const allBookingsContext = createContext();
export const viewOnlyFormContext = createContext();
export const getUsersFunctionContext = createContext();
export const filterEventsContext = createContext();
export const openCancelConfirmContext = createContext();

const ContextProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [myResources, setMyResources] = useState([]);
  const [allResources, setAllResources] = useState([]);
  const [currentLocation, setCurrentLocation] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [isBookingFormOpen, setIsBookingFormOpen] = useState(false);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(null);
  const [viewOnlyForm, setViewOnlyForm] = useState(false);
  const [allUserData, setAllUserData] = useState();
  const [bookingHistory, setBookingHistory] = useState([]);
  const [allBookings, setAllBookings] = useState([{}]);
  const [creatingEvent, setCreatingEvent] = useState({
    resourceId: "",
    start: "",
    end: "",
  });
  const [updatingEvent, setUpdatingEvent] = useState();

  const authToken = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))?.authToken
    : "";
  // Set default header for all axios requests
  axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;

  // Logout the current user
  const unAuthorizeUser = () => {
    setCurrentUser();
    localStorage.clear();
    localStorage.removeItem("currentUser");
    return <Navigate to="/login" />;
  };

  // Set currentUser from localStorage
  useEffect(() => {
    setCurrentUser(localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "");
  }, []);

  // Get All Locations
  const getLocations = () => {
    if (currentUser)
      axios
        .get(`${process.env.REACT_APP_API_URL}/getLocations`)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            unAuthorizeUser();
          }
          setLocations([{ city: "No locations found" }]);
        });
  };

  // Get All Users
  const getUsers = () => {
    if (currentUser)
      axios
        .get(`${process.env.REACT_APP_API_URL}/users`)
        .then((res) => {
          setAllUserData(res.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            unAuthorizeUser();
          }
          setAllUserData("No users found");
        });
  };

  // Get All Resources
  const getResources = () => {
    if (currentUser)
      axios
        .get(`${process.env.REACT_APP_API_URL}/getResource`)
        .then((res) => {
          setAllResources(res.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            unAuthorizeUser();
          }
          setAllResources([{ roomName: "No resources found" }]);
        });
  };

  // Get bookings by organizer
  const getBookingsByOrg = () => {
    if (currentUser)
      axios
        .get(`${process.env.REACT_APP_API_URL}/getBookingByOrg?id=${currentUser._id}`)
        .then((res) => {
          setBookingHistory(res.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            unAuthorizeUser();
          }
          setBookingHistory([]);
        });
  };

  // Get All Bookings
  const getAllBookings = async () => {
    if (currentUser)
      await axios
        .get(`${process.env.REACT_APP_API_URL}/getBookingByOrg`)
        .then((res) => {
          setAllBookings(res.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            unAuthorizeUser();
          }
          setAllBookings([{ title: "No history found" }]);
        });
  };

  // Set current location
  useEffect(() => {
    setCurrentLocation(currentUser?.location);
  }, [currentUser]);

  // getBookingsByOrg
  useEffect(() => {
    if (!openCancelConfirm) {
      getBookingsByOrg();
      getAllBookings();
    }
  }, [currentUser, isBookingFormOpen, openCancelConfirm]);

  // Location Resource
  useEffect(() => {
    if (allResources.length)
      setMyResources(allResources?.filter((resource) => resource?.location?._id === currentLocation));
  }, [currentLocation, allResources, currentUser]);

  // Loads location, users, resources
  useEffect(() => {
    getLocations();
    getUsers();
    getResources();
  }, [currentUser]);

  // Filter Upcoming Bookings
  const filterUpcomingEvents = (events) => {
    return events
      ?.filter((item) => {
        if (!item._id) return false;
        const endDate = new Date(item.rrule.type === 1 ? item.startDate : item.endDate);
        const end = new Date(item.end);
        const eventEnd = new Date(endDate.toDateString() + " " + end.toTimeString());
        return eventEnd > new Date();
      })
      .sort((a, b) => {
        const dateA = new Date(`${a.startDate}T${a.time}`);
        const dateB = new Date(b.start);
        return dateA.getTime() - dateB.getTime();
      });
  };
  // Filter Completed Events
  const filterCompletedEvents = (events) => {
    return events
      ?.filter((item) => {
        if (!item._id) return false;
        const endDate = new Date(item.rrule.type === 1 ? item.startDate : item.endDate);
        const end = new Date(item.end);
        const eventEnd = new Date(endDate.toDateString() + " " + end.toTimeString());
        return eventEnd < new Date();
      })
      .sort((a, b) => {
        const dateA = new Date(a.end);
        const dateB = new Date(b.end);
        return dateB.getTime() - dateA.getTime();
      })
      .sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateB.getDate() - dateA.getDate();
      })
      .sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateB.getMonth() - dateA.getMonth();
      });
  };

  return (
    <>
      <isBookingFormOpenContext.Provider
        value={useMemo(() => [isBookingFormOpen, setIsBookingFormOpen], [isBookingFormOpen])}
      >
        <openCancelConfirmContext.Provider
          value={useMemo(() => [openCancelConfirm, setOpenCancelConfirm], [openCancelConfirm])}
        >
          <locationsContext.Provider value={useMemo(() => [locations, setLocations], [locations])}>
            <currentLocationContext.Provider
              value={useMemo(() => [currentLocation, setCurrentLocation], [currentLocation])}
            >
              <currentUserContext.Provider value={useMemo(() => [currentUser, setCurrentUser], [currentUser])}>
                <allUserDataContext.Provider value={useMemo(() => [allUserData, setAllUserData], [allUserData])}>
                  <bookingHistoryContext.Provider
                    value={useMemo(() => [bookingHistory, setBookingHistory], [bookingHistory])}
                  >
                    <creatingEventContext.Provider
                      value={useMemo(() => [creatingEvent, setCreatingEvent], [creatingEvent])}
                    >
                      <updatingEventContext.Provider
                        value={useMemo(() => [updatingEvent, setUpdatingEvent], [updatingEvent])}
                      >
                        <myResourcesContext.Provider
                          value={useMemo(() => [myResources, setMyResources], [myResources])}
                        >
                          <allResourcesContext.Provider
                            value={useMemo(() => [allResources, setAllResources], [allResources])}
                          >
                            <allBookingsContext.Provider
                              value={useMemo(() => [allBookings, setAllBookings], [allBookings])}
                            >
                              <viewOnlyFormContext.Provider
                                value={useMemo(() => [viewOnlyForm, setViewOnlyForm], [viewOnlyForm])}
                              >
                                <filterEventsContext.Provider
                                  value={useMemo(() => [filterUpcomingEvents, filterCompletedEvents], [])}
                                >
                                  {children}
                                </filterEventsContext.Provider>
                              </viewOnlyFormContext.Provider>
                            </allBookingsContext.Provider>
                          </allResourcesContext.Provider>
                        </myResourcesContext.Provider>
                      </updatingEventContext.Provider>
                    </creatingEventContext.Provider>
                  </bookingHistoryContext.Provider>
                </allUserDataContext.Provider>
              </currentUserContext.Provider>
            </currentLocationContext.Provider>
          </locationsContext.Provider>
        </openCancelConfirmContext.Provider>
      </isBookingFormOpenContext.Provider>
    </>
  );
};

export default ContextProvider;
