const theme_color = "#5051F9";

const black = "#000000";
const white = "#FFFFFF";

// Schedular Component
const passed_time = "#edede9";

const upcoming_event_1 = "#69A956";
const upcoming_event_2 = "#";
const upcoming_event_3 = "#EF6351";

const completed_event_1 = "#A6D6A4";
const completed_event_2 = "#";
const completed_event_3 = "#EFAEA3";

export const colors = {
  theme_color,
  black,
  white,
  passed_time,
  upcoming_event_1,
  upcoming_event_2,
  upcoming_event_3,
  completed_event_1,
  completed_event_2,
  completed_event_3,
};
