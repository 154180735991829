import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { colors } from "../theme/colors";
import HistoryCard from "./HistoryCard";
import {
  bookingHistoryContext,
  currentLocationContext,
  filterEventsContext,
  myResourcesContext,
} from "../context/ContextProvider";
import { Box, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const History = () => {
  const [filterUpcomingEvents, filterCompletedEvents] = useContext(filterEventsContext);
  const [currentLocation] = useContext(currentLocationContext);
  const [bookingHistory] = useContext(bookingHistoryContext);
  const [myResources] = useContext(myResourcesContext);

  const [isViewMore, setIsViewMore] = useState(0);

  const [upcomingEvents, setUpcomingEvents] = useState(
    filterUpcomingEvents(bookingHistory.filter((h) => myResources.map((r) => r._id).includes(h.resourceId)))
  );
  const [completedEvents, setCompletedEvents] = useState(
    filterCompletedEvents(bookingHistory.filter((h) => myResources.map((r) => r._id).includes(h.resourceId)))
  );

  useEffect(() => {
    setUpcomingEvents(() =>
      filterUpcomingEvents(bookingHistory.filter((h) => myResources.map((r) => r._id).includes(h.resourceId)))
    );
    setCompletedEvents(() =>
      filterCompletedEvents(bookingHistory.filter((h) => myResources.map((r) => r._id).includes(h.resourceId)))
    );
  }, [bookingHistory, currentLocation, myResources]);

  return (
    <div>
      {bookingHistory?.length !== 0 && (
        <div className="gap-3 d-flex flex-column">
          {/* start upcoming */}
          {upcomingEvents?.length !== 0 && (
            <div
              className="py-2"
              style={{
                background: colors.theme_color,
                borderRadius: "15px",
              }}
            >
              <div className="d-flex justify-content-center">
                <label
                  className="large"
                  style={{
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: colors.white,
                  }}
                >
                  My Upcoming Events
                </label>
              </div>

              <Box className="px-3 gap-2 d-flex flex-column my-1">
                {upcomingEvents?.slice(0, 4).map((event, index) => (
                  <HistoryCard key={event?._id} event={event} upcoming={true} isEditable={true} />
                ))}
              </Box>

              {upcomingEvents?.length > 4 && (
                <div className="d-flex justify-content-center">
                  <label
                    className="small"
                    style={{
                      color: colors.white,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsViewMore(1);
                    }}
                  >
                    View More
                  </label>
                </div>
              )}
            </div>
          )}
          {/* end upcoming */}

          {/* start completed */}
          {completedEvents?.length !== 0 && (
            <div className="px-0" style={{ background: colors.white }}>
              <div className="d-flex justify-content-center">
                <label
                  className="large"
                  style={{
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: colors.black,
                  }}
                >
                  My Completed Events
                </label>
              </div>

              <Box className="px-3">
                {completedEvents?.slice(0, 4).map((event) => (
                  <HistoryCard key={event?._id} event={event} upcoming={false} isEditable={false} />
                ))}
              </Box>

              {completedEvents?.length > 4 && (
                <div className="d-flex justify-content-center">
                  <label
                    className="small"
                    style={{
                      color: colors.black,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsViewMore(2);
                    }}
                  >
                    View More
                  </label>
                </div>
              )}
            </div>
          )}
          {/* end completed */}
        </div>
      )}
      {/* View More Drawer */}
      <Drawer
        open={Boolean(isViewMore)}
        variant="temporary"
        anchor="right"
        onClose={() => {
          setIsViewMore(0);
        }}
        PaperProps={{ sx: { width: "20%" } }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5
            className="mx-4 mt-4 mb-3"
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              color: colors.black,
            }}
          >
            {isViewMore === 1 ? "Upcoming Events" : "Completed Events"}
          </h5>
          <IconButton onClick={() => setIsViewMore(0)} className="me-3 mt-2">
            <CloseIcon />
          </IconButton>
        </div>
        <hr className="mt-0 mx-3 mb-0" />
        <Box className="px-3 my-2" sx={{ overflowY: "auto", scrollBehavior: "auto" }}>
          {bookingHistory.length && isViewMore === 1
            ? upcomingEvents.map((event) => (
                <HistoryCard key={event?._id} event={event} upcoming={false} isEditable={isViewMore === 1} />
              ))
            : completedEvents.map((event) => (
                <HistoryCard key={event?._id} event={event} upcoming={false} isEditable={isViewMore === 1} />
              ))}
        </Box>
      </Drawer>
    </div>
  );
};

export default History;
