import { useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Form from "../components/bookingForm/Form";
import History from "../components/History";
import Scheduler from "../components/scheduler/Scheduler";
import { bookingHistoryContext, openCancelConfirmContext } from "../context/ContextProvider";
import moment from "moment";

const Dashboard = () => {
  const [bookingHistory] = useContext(bookingHistoryContext);
  const [openCancelConfirm, setOpenCancelConfirm] = useContext(openCancelConfirmContext);
  return (
    <>
      <div className="d-flex flex-fill">
        <div className="bg-white m-3 py-2 flex-fill">
          <Scheduler />
        </div>

        {/* History Section */}
        {bookingHistory?.length !== 0 ? (
          <div className="bg-white p-2" style={{ width: "18%" }}>
            <History />
          </div>
        ) : (
          ""
        )}

        <Form />
        <Dialog
          open={Boolean(openCancelConfirm)}
          keepMounted
          onClose={() => setOpenCancelConfirm(null)}
          aria-describedby="alert-dialog-slide-description"
        >
          {Boolean(openCancelConfirm) && (
            <>
              <DialogTitle>{"You are about to cancel your booking..!"}</DialogTitle>
              <DialogContent>Title : {openCancelConfirm?.split("$")[1]}</DialogContent>
            </>
          )}
          <DialogActions>
            {/*  openCancelConfirm
                    0 => eventId
                    1 => Title
                    2 => rrule.type
                    3 => selectedInstance.start   */}
            <Button onClick={() => setOpenCancelConfirm(null)}>No</Button>
            {openCancelConfirm &&
              [2, 3].includes(Number(openCancelConfirm.split("$")[2])) &&
              Boolean(openCancelConfirm.split("$")[3]) && (
                <Button
                  color="error"
                  onClick={() => {
                    var instanceTime = moment(openCancelConfirm.split("$")[3]);
                    axios
                      .patch(`${process.env.REACT_APP_API_URL}/cancelBooking/${openCancelConfirm.split("$")[0]}`, {
                        exclude: instanceTime.format(),
                        timeZone: instanceTime.format("Z"),
                      })
                      .then((res) => {
                        setOpenCancelConfirm(null);
                        toast.success(res.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      })
                      .catch((err) => {
                        toast.error(err.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      });
                  }}
                >
                  Delete This Instance Only
                </Button>
              )}
            <Button
              color="error"
              onClick={() => {
                axios
                  .patch(`${process.env.REACT_APP_API_URL}/cancelBooking/${openCancelConfirm.split("$")[0]}`, {
                    exclude: [],
                    timeZone: moment().format("Z"),
                  })
                  .then((res) => {
                    setOpenCancelConfirm(null);
                    toast.success(res.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  })
                  .catch((err) => {
                    toast.error(err.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  });
              }}
            >
              {openCancelConfirm && [2, 3].includes(Number(openCancelConfirm.split("$")[2]))
                ? "Delete All Instances"
                : "Yes"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Copy Rights */}
      <div className="bg-white d-flex justify-content-center " style={{ fontSize: "12px" }}>
        © {new Date().getFullYear()} COPYRIGHT GCRB - All Rights Reserved.
        <a target="_blank" href="https://thegatewaycorp.com/" style={{ textDecoration: "none" }}>
          [The Gateway Group Company]
        </a>
      </div>
    </>
  );
};
export default Dashboard;
