import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../assets/logo.png";
import Select from "@mui/joy/Select";
import { Option } from "@mui/joy";
import { LocationCityOutlined, Logout, MoreVert, Try } from "@mui/icons-material";
import {
  Button,
  Avatar,
  MenuItem,
  ListItemIcon,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { currentLocationContext, currentUserContext, locationsContext } from "../context/ContextProvider";
import axios from "axios";
import MyMenu from "./common/MyMenu";

const Topbar = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useContext(currentUserContext);
  const [locations] = useContext(locationsContext);
  const [currentLocation, setCurrentLocation] = useContext(currentLocationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [feedback, setFeedback] = useState();

  return (
    <>
      <div className="d-flex bg-white">
        <div
          className="px-2 flex-fill d-flex bg-white justify-content-between align-items-center"
          style={{ background: "#fff", width: "" }}
        >
          <div className="logo hover-zoom mx-3">
            <img src={logo} alt="CRB" width="50rem" height="50rem" />
          </div>
          <h4 className="m-0 flex-fill">Conference Room Booking</h4>
          <Select
            className="me-5"
            variant="soft"
            value={currentLocation ? currentLocation : ""}
            startDecorator={<LocationCityOutlined />}
            sx={{ width: 240 }}
            onChange={(e, newValue) => {
              setCurrentLocation(newValue);
            }}
          >
            {Boolean(locations?.length) &&
              locations?.map((location) => {
                return (
                  <Option style={{ background: "#fff" }} value={location._id} key={location._id}>
                    {location.city}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* start user */}
        <div
          id="user"
          className="d-flex align-self-end px-0"
          style={{
            width: "18%",
            borderBottom: "1px solid #ccc",
            boxShadow: "0px 0.6px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div className="flex-fill p-2 d-flex align-items-center justify-content-between">
            <div className="mx-3 my-1">
              <Avatar>{`${currentUser?.userName?.split(" ")[0][0] + currentUser?.userName?.split(" ")[1][0]}`}</Avatar>
            </div>
            <div className="ms-1 flex-fill d-flex flex-column">
              <label style={{ color: "#1A1919" }}>{currentUser?.userName}</label>
              <label style={{ color: "#B2B2B2" }}>{currentUser?.userRole}</label>
            </div>
            <div>
              <Button
                className="py-2 px-0"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert
                  sx={{
                    fontWeight: "bold",
                    // mx: 0.5,
                    fontSize: 30,
                    color: "#B2B2B2",
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
        {/* end user */}
      </div>

      <MyMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          key={1}
          onClick={() => {
            setAnchorEl(null);
            setOpenDialog(true);
          }}
        >
          <ListItemIcon>
            <Try fontSize="small" />
          </ListItemIcon>
          Feedbacks
        </MenuItem>
        <Divider key={2} />
        <MenuItem
          key={3}
          onClick={() => {
            localStorage.removeItem("currentUser");
            axios.get(`${process.env.REACT_APP_API_URL}/logoutUser/${currentUser?._id}`).then((res) => {
              toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT });
              navigate("/login");
            });
            setCurrentUser();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MyMenu>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <TextField
            minRows={5}
            multiline
            autoFocus
            margin="dense"
            id="name"
            placeholder="Your feedbacks here..."
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setFeedback(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              const mailBody = {
                recipients: `${process.env.REACT_APP_EMAIL}`,
                subject: `CRB feedback from ${currentUser.userName}`,
                message: `<table>
                    <tr>
                      <td>Sender</td>
                      <td>:</td>
                      <td>${currentUser?.userName}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>${currentUser?.email}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        ${locations.filter((l) => l?._id === currentUser?.location)[0]?.city}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" height="20px"}></td>
                    </tr>
                    <tr>
                      <td valign="top">Description</td>
                      <td valign="top">:</td>
                      <td><pre style="font-family: inherit">${feedback}</pre></td>
                    </tr>
                  </table>`,
                from: currentUser?.email,
              };
              axios.post(`${process.env.REACT_APP_API_URL}/sendEmail`, mailBody).then((res) => {
                res.data.success
                  ? toast.success("Thank you for you valuable feedbacks!", { position: toast.POSITION.TOP_RIGHT })
                  : toast.error("Sorry!, we are unable to process your feedbacks.", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
              });
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Topbar;
