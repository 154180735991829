import React, { useState, useEffect, useContext } from "react";
import "../style.css";
import { colors } from "../../theme/colors";
import { date_n_time } from "../../theme/date_n_time";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import withDragDropContext from "./withDnDContext";
import { toast } from "react-toastify";
import { Row, Col, Table } from "react-bootstrap";
import "react-big-scheduler/lib/css/style.css";
import {
  allBookingsContext,
  creatingEventContext,
  currentUserContext,
  filterEventsContext,
  isBookingFormOpenContext,
  myResourcesContext,
  openCancelConfirmContext,
  updatingEventContext,
  viewOnlyFormContext,
} from "../../context/ContextProvider";
import { Card, Drawer, IconButton, Button, Popover, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import InfoIcon from "@mui/icons-material/Info";
import { RRule } from "rrule";
import { AutoDelete, NoteAlt, OpenInNew } from "@mui/icons-material";

const Basic = () => {
  const [isBookingFormOpen, setIsBookingFormOpen] = useContext(isBookingFormOpenContext);
  const [, setOpenCancelConfirm] = useContext(openCancelConfirmContext);
  const [viewOnlyForm, setViewOnlyForm] = useContext(viewOnlyFormContext);
  const [allBookings] = useContext(allBookingsContext);
  const [, setCreatingEvent] = useContext(creatingEventContext);
  const [, setUpdatingEvent] = useContext(updatingEventContext);
  const [myResources] = useContext(myResourcesContext);
  const [currentUser] = useContext(currentUserContext);
  const [filterUpcomingEvents] = useContext(filterEventsContext);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [, setUpdate] = useState();
  const [resourceDrawerData, setResourceDrawerData] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, []);

  const [viewModel, setViewModel] = useState(
    new SchedulerData(
      new Date().toString(),
      ViewTypes.Day,
      false,
      false,
      {
        checkConflict: true,
        endResizable: false,
        startResizable: false,
        movable: false,
        recurringEventsEnabled: true,
        defaultEventBgColor: colors.upcoming_event_3, // "#e98074",
        schedulerWidth: "75%",
        schedulerMaxWidth: "50%",
        eventItemHeight: 40,
        schedulerMaxHeight: "680",
        eventItemPopoverEnabled: false,
        eventItemLineHeight: 42,
        resourceName: windowWidth < "1400" ? "Conference Rooms (Cap.)" : "Conference Rooms (Capacity)",
        minuteStep: 15,
        dayStartFrom: date_n_time.office_start,
        dayStopTo: date_n_time.office_end - 1,
        dayCellWidth: windowWidth > "1400" ? 17 : 15,
        weekCellWidth: 150,
        dayResourceTableWidth: windowWidth < "1400" ? "30%" : "25%",
        weekResourceTableWidth: windowWidth < "1400" ? "30%" : "25%",
        nonWorkingTimeHeadBgColor: "transparent",
        nonWorkingTimeBodyBgColor: "transparent",
        nonWorkingTimeHeadColor: "default",
        views: [
          {
            viewName: "Day",
            viewType: 0,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: 1,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      },
      {
        getNonAgendaViewBodyCellBgColorFunc: (schedulerData, slotId, header) => {
          if (new Date(header.time) < new Date()) {
            return colors.passed_time;
          }
          return undefined;
        },
      }
    )
  );

  const removeEventById = (id) => {
    const schedulerEvents = viewModel.events;
    const index = schedulerEvents.filter((event) => event.id !== id);
    if (index !== -1) {
      let newViewModel = viewModel;
      newViewModel.events = index;
      setViewModel(newViewModel);
    }
  };

  const getBackGroundColor = (event) => {
    if (currentUser?.username === event?.organizer?.username)
      return filterUpcomingEvents([event]).length ? colors.upcoming_event_1 : colors.completed_event_1;
    else return filterUpcomingEvents([event]).length ? colors.upcoming_event_3 : colors.completed_event_3;
  };

  useEffect(() => {
    const fetchResources = async () => {
      if (myResources.length !== 0) {
        const resourcess = await myResources.map((r) => {
          const resource = r;
          return {
            id: resource._id,
            name: (
              <div className="d-flex align-items-center gap-1" style={{ color: colors.theme_color }}>
                <InfoIcon sx={{ fontSize: 20 }} />
                <p className="m-0">{resource.roomName + " (" + resource.capacity + ")"}</p>
              </div>
            ),
          };
        });
        setResources(resourcess);
        viewModel.setResources(resourcess);
      }
    };
    fetchResources();
  }, [myResources, viewModel]);

  useEffect(() => {
    const fetchHistory = async () => {
      if (allBookings !== "") {
        const history = await allBookings?.map((e) => {
          const event = e;
          const weekDays = { MO: 0, TU: 1, WE: 2, TH: 3, FR: 4, SA: 5, SU: 6 };
          const rType = { 2: 3, 3: 2 };
          const rDays = event.rrule?.days?.map((e) => e.slice(0, 2).toUpperCase());
          // returns a date object with date from 2nd param and time from 3rd param
          const getEventTime = (event, date, time) => {
            return `${new Date(event[date]).getFullYear()}-${(new Date(event[date]).getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${new Date(event[date]).getDate()} ${new Date(event[time]).toLocaleTimeString(
              "en-US",
              { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }
            )}`;
          };
          const options = {
            dtstart: new Date(event?.startDate),
            until: new Date(getEventTime(event, "endDate", "end")),
          };
          event?.rrule?.type !== 1 && (options.freq = rType[event?.rrule?.type]);
          event?.rrule?.type === 3 && (options.byweekday = rDays?.map((day) => weekDays[day]));

          let rrule = event?.rrule?.type !== 1 ? new RRule(options) : "";

          return {
            id: event._id,
            organizer: event?.organizer,
            title: event.title,
            description: event.description,
            resourceId: event.resourceId,
            start: getEventTime(event, "startDate", "start"),
            end: getEventTime(event, "startDate", "end"),
            rrule: rrule.toString(),
            exclude: event?.rrule?.exclude ?? [],
            isRecurring: event.rrule.type !== 1,
            bgColor: getBackGroundColor(event),
          };
        });
        if (Boolean(history) && history[0]?.id !== undefined) {
          setEvents(() => history);
          viewModel.setEvents(history);
        }
        setUpdate(viewModel);
      }
    };
    fetchHistory();
  }, [allBookings, currentUser, viewModel]);

  useEffect(() => {
    viewModel.localeMoment.locale("en");
    if (resources.length !== 0) {
      viewModel.setResources(resources);
    }
    if (events.length !== 0) {
      viewModel.setEvents(events);
    }
  }, [events, resources, viewModel]);

  const prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(events);
    setUpdate(schedulerData.endDate);
    setViewModel(schedulerData);
  };

  const nextClick = (schedulerData) => {
    schedulerData.next();

    schedulerData.setEvents(events);
    setUpdate(schedulerData.startDate);
    setViewModel(schedulerData);
  };

  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(events);
    setUpdate(schedulerData.selectDate);
    setViewModel(schedulerData);
  };

  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(events);
    setUpdate(schedulerData.viewType);
    setViewModel(schedulerData);
  };

  const newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (new Date() > new Date(start)) {
      // This code can be used for warning messages if the slot is not available
      // toast.error(`Sorry!!! You cannot book in past.`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      let startTime = new Date(start);
      startTime.setFullYear(1970);
      startTime.setMonth(0);
      startTime.setDate(1);
      if (startTime.getHours() < date_n_time.office_start) startTime.setHours(date_n_time.office_start, 0, 0, 0);
      let endTime = new Date(end);
      endTime.setFullYear(1970);
      endTime.setMonth(0);
      endTime.setDate(1);
      if (endTime.getHours() > date_n_time.office_end) endTime.setHours(date_n_time.office_end, 0, 0, 0);
      setViewOnlyForm(true);
      setIsBookingFormOpen(!isBookingFormOpen);
      setCreatingEvent({
        resourceId: slotId,
        start: startTime,
        end: endTime,
        startDate: new Date(new Date(start).toDateString()),
        endDate: new Date(new Date(end).toDateString()),
      });
    }
  };

  const conflictOccurred = (schedulerData, action, event, type, slot, slotName) => {
    const slot_name = myResources.filter((resource) => resource._id === slot)[0];
    toast.error(`Sorry this slot is not available for ${slot_name["roomName"]}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    setUpdate(schedulerData.toggleExpandStatus(slotId));
    return {
      viewModel: schedulerData,
    };
  };

  const slotClickedFunc = (schedulerData, slot, slotId) => {
    setResourceDrawerData(myResources.filter((resource) => resource._id === slot.slotId)[0]);
    const startTime = new Date();
    startTime.setFullYear(1970);
    startTime.setMonth(0);
    startTime.setDate(1);
    const endTime = new Date(startTime);
    setCreatingEvent({
      resourceId: slot.slotId,
      startDate: new Date(new Date().toDateString()),
      endDate: new Date(new Date().toDateString()),
      start: startTime,
      end: endTime.setMinutes(endTime.getMinutes() + 30),
    });
  };

  const eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor, eventId) => {
    return (
      <div style={{ width: "250px" }}>
        <Row type="flex" align="">
          <Col type="" className="d-flex align-items-center gap-2">
            <div className="status-dot" style={{ backgroundColor: statusColor }} />
            <span className="header2-text" title={title}>
              {title}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="">
          <Col type="flex">
            <span className="header1-text">{start.format("HH:mm")}</span>
            <span className="header2-text">{start.toString().slice(3, 10)}</span>
            <span className="header1-text">-{end.format("HH:mm")}</span>
            <span className="header2-text">{end.toString().slice(3, 10)}</span>
          </Col>
        </Row>

        {currentUser?.username === allBookings.find((e) => e._id === eventItem.id.split("-")[0])?.organizer?.username &&
          (filterUpcomingEvents(allBookings)
            ?.map((e) => e._id)
            .includes(eventItem.id.split("-")[0]) ? (
            <Row type="flex" align="">
              <Col>
                <Button
                  onClick={() => {
                    setOpenCancelConfirm(
                      `${eventItem?.id.split("-")[0]}$${
                        allBookings.find((e) => e._id === eventItem.id.split("-")[0]).title
                      }`
                    );
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setUpdatingEvent(allBookings.find((e) => e._id === eventItem.id.split("-")[0]));
                    setViewOnlyForm(true);
                    setIsBookingFormOpen(true);
                  }}
                >
                  Update
                </Button>
              </Col>
            </Row>
          ) : (
            <Row type="flex" align="">
              <Col>
                <Button
                  onClick={() => {
                    const startTime = new Date();
                    startTime.setFullYear(1970);
                    startTime.setMonth(0);
                    startTime.setDate(1);
                    const endTime = new Date(startTime);

                    // Event to be Reboked
                    const eventToRebook = eventItem;
                    eventToRebook.startDate = new Date(new Date().toDateString());
                    eventToRebook.endDate = new Date(new Date().toDateString());
                    eventToRebook.start = startTime;
                    eventToRebook.end = endTime.setMinutes(endTime.getMinutes() + 30);
                    setCreatingEvent(eventToRebook);
                    setViewOnlyForm(true);
                    setIsBookingFormOpen(!isBookingFormOpen);
                  }}
                >
                  Rebook
                </Button>
              </Col>
            </Row>
          ))}
      </div>
    );
  };

  const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
    const excludes = event?.exclude?.map((d) => new Date(d).toDateString()) ?? [];
    const thisEvent = new Date(event?.start)?.toDateString() ?? "";
    const flag = excludes.includes(thisEvent);
    if (flag) removeEventById(event.id);
    let backgroundColor;
    if (currentUser?.username === event?.organizer?.username)
      backgroundColor = new Date(event?.end) > new Date() ? colors.upcoming_event_1 : colors.completed_event_1;
    else backgroundColor = new Date(event?.end) > new Date() ? colors.upcoming_event_3 : colors.completed_event_3;
    return (
      !flag && (
        <div
          onClick={(e) => {
            const { clientX, clientY } = e;
            setAnchorEl({ top: clientY, left: clientX });
            setSelectedEvent({
              ...allBookings.find((e) => e._id === event.id.split("-")[0]),
              selectedInstance: event,
            });
          }}
          className="px-2"
          style={{
            marginTop: "1px",
            borderRadius: "13px",
            color: colors.white,
            maxHeight: "40px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor,
          }}
        >
          <span className="m-0 p-0 " style={{ lineHeight: "40px" }}>
            {allBookings.find((e) => e._id === event.id.split("-")[0])?.title}
          </span>
        </div>
      )
    );
  };

  return (
    <>
      <Scheduler
        schedulerData={viewModel}
        prevClick={prevClick}
        nextClick={nextClick}
        onSelectDate={onSelectDate}
        onViewChange={onViewChange}
        newEvent={newEvent}
        conflictOccurred={conflictOccurred}
        toggleExpandFunc={toggleExpandFunc}
        slotClickedFunc={slotClickedFunc}
        eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
        eventItemTemplateResolver={eventItemTemplateResolver}
      />

      <Popover
        anchorReference="anchorPosition"
        anchorPosition={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <div className="" style={{ width: "250px", fontSize: "14px" }}>
          <Row type="flex" className="m-0 p-0">
            <Col className="d-flex align-items-center">
              <div
                className="status-dot"
                style={{
                  backgroundColor: getBackGroundColor(selectedEvent),
                }}
              />
              <span className="header2-text flex-fill ms-2">{selectedEvent?.organizer?.userName}</span>

              <IconButton onClick={() => setAnchorEl(null)} className="m-0">
                <Tooltip placement="bottom" title="Close">
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </Tooltip>
              </IconButton>
            </Col>
          </Row>
          <Table borderless={true} responsive="md" className="mx-1 my-0">
            <tbody>
              <tr>
                <td>Title</td>
                <td>:</td>
                <td>{selectedEvent?.title}</td>
              </tr>
              <tr>
                <td>Resource</td>
                <td>:</td>
                <td>{myResources.find((r) => r._id === selectedEvent.resourceId)?.roomName}</td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <div className="d-flex mx-3 align-items-center gap-1">
            <span className="header1-text" style={{ fontSize: "20px" }}>
              {new Date(selectedEvent?.start).toTimeString().slice(0, 5)}
            </span>
            <span className="header2-text">{new Date(selectedEvent?.startDate)?.toString().slice(3, 10)}</span>
            <span>-</span>
            <span className="header1-text" style={{ fontSize: "20px" }}>
              {new Date(selectedEvent?.end).toTimeString().slice(0, 5)}
            </span>
            <span className="header2-text">{new Date(selectedEvent?.endDate)?.toString().slice(3, 10)}</span>
          </div>

          <div className="d-flex  mx-2" style={{ fontSize: "14px" }}>
            {(currentUser?.username === allBookings?.find((e) => e._id === selectedEvent?._id)?.organizer?.username ||
              currentUser?.username === allBookings?.find((e) => e._id === selectedEvent?._id)?.bookedBy?.username) &&
              (new Date(selectedEvent?.selectedInstance?.end) > new Date() ? (
                <>
                  <IconButton
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenCancelConfirm(
                        `${selectedEvent?._id}$${selectedEvent?.title}$${selectedEvent?.rrule?.type}$${selectedEvent?.selectedInstance?.start}`
                      );
                    }}
                  >
                    <Tooltip placement="bottom" title="Cancel">
                      <AutoDelete
                        color="error"
                        sx={{
                          fontSize: 20,
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setAnchorEl(null);
                      setUpdatingEvent(allBookings.filter((e) => e._id === selectedEvent._id)[0]);
                      setViewOnlyForm(true);
                      setIsBookingFormOpen(true);
                    }}
                  >
                    <Tooltip placement="bottom" title="Update">
                      <NoteAlt sx={{ color: colors.theme_color, fontSize: 20 }} />
                    </Tooltip>
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={() => {
                      setAnchorEl(null);
                      // Gives Current Time with Date 01/01/19770
                      const startTime = new Date();
                      startTime.setFullYear(1970);
                      startTime.setMonth(0);
                      startTime.setDate(1);
                      const endTime = new Date(startTime);
                      // Event to be Reboked
                      const eventToRebook = allBookings.filter((e) => e._id === selectedEvent._id)[0];
                      eventToRebook.startDate = new Date(new Date().toDateString());
                      eventToRebook.endDate = new Date(new Date().toDateString());
                      eventToRebook.start = startTime;
                      eventToRebook.end = endTime.setMinutes(endTime.getMinutes() + 30);
                      setCreatingEvent(eventToRebook);
                      setViewOnlyForm(true);
                      setIsBookingFormOpen(!isBookingFormOpen);
                    }}
                  >
                    <Tooltip placement="bottom" title="Rebook">
                      <EventRepeatIcon
                        sx={{
                          color: colors.theme_color,
                          fontSize: 20,
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </>
              ))}
            <div className="flex-fill"></div>
            <IconButton
              className="m-0"
              onClick={() => {
                setAnchorEl(null);
                setCreatingEvent(allBookings.filter((e) => e._id === selectedEvent?._id.split("-")[0])[0]);
                setIsBookingFormOpen(true);
              }}
            >
              <Tooltip placement="bottom" title="Expand">
                <OpenInNew className="m-0 p-0" sx={{ fontSize: "1.2rem", color: colors.theme_color }} />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      </Popover>

      <Drawer
        open={Boolean(resourceDrawerData)}
        variant="temporary"
        anchor="left"
        onClose={() => {
          setResourceDrawerData("");
          setViewOnlyForm(!viewOnlyForm);
        }}
        PaperProps={{ sx: { width: "22%" } }}
      >
        <Card
          className="p-3 "
          variant="standard"
          sx={{
            justifyContent: "center",
            padding: 5,
            alignItems: "center",
          }}
        >
          <h4 className="ms-2 d-flex align-items-center justify-content-between">
            Room Details
            <IconButton onClick={() => setResourceDrawerData("")}>
              <CloseIcon />
            </IconButton>
          </h4>
          <div className="table-borderless table mt-4" style={{ fontFamily: "inherit", fontSize: "1rem" }}>
            <table className="align-top table-condensed  m-0">
              <tbody>
                <tr>
                  <td height={10} colSpan={3}></td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <h6>{resourceDrawerData?.roomName}</h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="p-0">
                    <hr className="mt-0" />
                  </td>
                </tr>

                <tr>
                  <td>Capacity</td>
                  <td>:</td>
                  <td>{resourceDrawerData?.capacity} Persons</td>
                </tr>

                <tr>
                  <td height={30} colSpan={3}></td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <h6>Location</h6>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="p-0">
                    <hr className="mt-0" />
                  </td>
                </tr>
                <tr>
                  <td>Floor</td>
                  <td>:</td>
                  <td>{resourceDrawerData?.floorLocation} </td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>:</td>
                  <td>{resourceDrawerData?.location?.city} </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>:</td>
                  <td>{resourceDrawerData?.location?.state} </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>:</td>
                  <td>{resourceDrawerData?.location?.country} </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>:</td>
                  <td>{resourceDrawerData?.location?.address} </td>
                </tr>

                <tr>
                  <td height={30} colSpan={3}></td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <h6>Amenities</h6>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="p-0">
                    <hr className="mt-0" />
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <ol className="ps-3">
                      {resourceDrawerData?.amenities?.map((a) => (
                        <li key={a}> {a}</li>
                      ))}
                    </ol>
                  </td>
                </tr>

                <tr>
                  <td height={30} colSpan={3}></td>
                </tr>
              </tbody>
            </table>

            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => {
                setViewOnlyForm(true);
                setIsBookingFormOpen(true);
                setResourceDrawerData("");
              }}
            >
              Book this room
            </Button>
          </div>
        </Card>
      </Drawer>
      {/* {console.clear()} */}
    </>
  );
};

export default withDragDropContext(Basic);
