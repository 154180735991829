import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { date_n_time } from "../../theme/date_n_time";
import {
  Autocomplete,
  Container,
  Drawer,
  Box,
  TextField,
  Card,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  styled,
  Switch,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Add, AutoDelete, NoteAlt, NoteAltOutlined } from "@mui/icons-material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  bookingHistoryContext,
  creatingEventContext,
  updatingEventContext,
  currentUserContext,
  isBookingFormOpenContext,
  viewOnlyFormContext,
  allResourcesContext,
  filterEventsContext,
  allBookingsContext,
  openCancelConfirmContext,
} from "../../context/ContextProvider";
import dayjs from "dayjs";
import moment from "moment";

const Form = () => {
  // Global states from Context Provider
  const [currentUser] = useContext(currentUserContext);
  const [viewOnlyForm, setViewOnlyForm] = useContext(viewOnlyFormContext);
  const [isBookingFormOpen, setIsBookingFormOpen] = useContext(isBookingFormOpenContext);
  const [, setOpenCancelConfirm] = useContext(openCancelConfirmContext);
  const [updatingEvent, setUpdatingEvent] = useContext(updatingEventContext);
  const [creatingEvent, setCreatingEvent] = useContext(creatingEventContext);
  const [bookingHistory, setBookingHistory] = useContext(bookingHistoryContext);
  const [allBookings, setAllBookings] = useContext(allBookingsContext);
  const [allResources] = useContext(allResourcesContext);
  const [filterUpcomingEvents, filterCompletedEvents] = useContext(filterEventsContext);

  //======== States for Auto complete ========
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchUserText, setSearchUserText] = useState("");
  const [searchForSomeone, setSearchForSomeone] = useState("");
  const [loadingAuto, setLoadingAuto] = useState(false);

  //======== sets the Main while creating new booking ========
  useEffect(() => {
    main.organizer = currentUser;
    if (creatingEvent) {
      setMain({ ...main, ...creatingEvent });
      setIsForSomeone(Object.keys(creatingEvent).includes("bookedBy"));
      setIsParticipants(Object.keys(creatingEvent).includes("participantsDetails"));
    }
  }, [creatingEvent, currentUser]);

  //======== sets the Main while editing existing booking ========
  useEffect(() => {
    if (updatingEvent) {
      setMain({ ...main, ...updatingEvent });
      setIsForSomeone(Object.keys(updatingEvent).includes("bookedBy"));
      setIsParticipants(Object.keys(updatingEvent).includes("participantsDetails"));
    }
  }, [updatingEvent]);

  //======== Main Store for All Fields ========
  const [main, setMain] = useState({
    title: "",
    description: "",
    organizer: currentUser,
    rrule: { type: 1, days: [], exclude: [] },
    isActive: true,
  });

  //======== Errors Store ========
  const initialStateErrors = {
    title: "",
    description: "",
    startDate: "",
    start: "",
    end: "",
    rrule: "",
    endDate: "",
    memberDetails: "",
    participantsDetails: "",
    bookedBy: "",
    meetingLink: "",
    virtualMeetingDesc: "",
    commentForFrontdesk: "",
    pName: "",
    pEmail: "",
  };
  const [errors, setErrors] = useState(initialStateErrors);

  //======== States to hide/show other details ========
  const [isForSomeone, setIsForSomeone] = useState(Object.keys(main).includes("bookedBy"));
  const [isParticipants, setIsParticipants] = useState(Object.keys(main).includes("participantsDetails"));

  //======== This onChange is for "description" and "commentForFrontdesk" fields ========
  const onChange = (e) => {
    setMain(() => {
      return {
        ...main,
        [e.target.name]: e.target.value.replace(/\n{2,}/g, `\n`),
      };
    });
  };

  //======== This function and variables are for Date abd Time fields ========
  const minTime = dayjs().set("hour", date_n_time.office_start).set("minute", 0).set("second", 0);
  const maxTime = dayjs().set("hour", date_n_time.office_end).set("minute", 0).set("second", 0);

  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }
  const handleLaterDate = (endValue) => {
    const date = new Date(endValue);
    const newDate = addWeeks(date, 1);
    return newDate;
  };
  //======== Validation Schema ========
  const currentTime = new Date();
  currentTime.setFullYear(1970);
  currentTime.setMonth(0);
  currentTime.setDate(1);
  const formValidationSchema = yup.object().shape({
    title: yup.string().required("Title is required").min(3, "Title must be at least 3 characters long"),
    startDate: yup
      .date()
      .required("Start date is required")
      .min(new Date(new Date().toDateString()), "Start date must be in the future"),
    endDate: yup.date().when("rrule.type", (type, schema) => {
      return type > 1
        ? schema
            .required("End date is required")
            .test("is-greater", "End date must be after start date", function (endDate) {
              const { startDate } = this.parent;
              return startDate <= endDate;
            })
        : schema;
    }),
    start: yup
      .date()
      .required("Start time is required")
      .when("startDate", (startDate, schema) => {
        return new Date(new Date(startDate).toDateString()) > new Date(new Date().toDateString())
          ? schema
          : schema.min(currentTime, "Start time must be in the future");
      })
      .test(
        "is-valid-time",
        `Start time should be between ${date_n_time.office_start}am and ${date_n_time.office_end}pm`,
        function (value) {
          const hour = new Date(value).getHours();
          return hour >= date_n_time.office_start && hour <= date_n_time.office_end;
        }
      ),
    end: yup
      .date()
      .required("End time is required")
      .when("start", (start, schema) => schema.min(start, "End time must be after start time"))
      .test(
        "is-valid-time",
        `End time should be between ${date_n_time.office_start}am and ${date_n_time.office_end}pm`,
        function (value) {
          const hour = new Date(value).getHours();
          return (
            hour >= date_n_time.office_start &&
            (hour < date_n_time.office_end || (hour === date_n_time.office_end && new Date(value).getMinutes() === 0))
          );
        }
      ),
    rrule: yup.object().shape({
      type: yup.number().required(),
    }),
    meetingLink: yup.string().url("Link is not valid"),
  });
  //======== Submit Method ========
  const onSubmit = async (e) => {
    await formValidationSchema
      .validate(main)
      .then(async (res) => {
        errors.title = "";
        errors.startDate = "";
        errors.endDate = "";
        errors.start = "";
        errors.end = "";
        errors.rrule = "";
        errors.virtualMeetingDesc = "";
        if (Object.values(errors).every((value) => value === "" && main?.start !== main?.end)) {
          var mainCopy = Object.assign({}, main);
          mainCopy.start = moment(mainCopy.start).format();
          mainCopy.end = moment(mainCopy.end).format();
          mainCopy.startDate = moment(mainCopy.startDate).format();
          mainCopy.endDate = moment(mainCopy.endDate).format();
          mainCopy.timeZone = moment().format("Z");
          if (Object.keys(mainCopy).includes("_id") && Boolean(updatingEvent)) {
            axios
              .patch(`${process.env.REACT_APP_API_URL}/updateBooking/${mainCopy._id}`, { main: mainCopy })
              .then(async (res) => {
                setBookingHistory([...bookingHistory, res.data.data]);
                setAllBookings([...allBookings, res.data.data]);
                setIsBookingFormOpen(!isBookingFormOpen);
                await toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                drawerOnClose();
              })
              .catch((err) => {
                console.log(err);
                if (err.response.data.data?._id !== mainCopy._id) {
                  toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              });
          } else {
            delete mainCopy?._id;
            axios
              .post(`${process.env.REACT_APP_API_URL}/postBooking`, { main: mainCopy })
              .then(async (res) => {
                setBookingHistory([...bookingHistory, res.data.data]);
                setAllBookings([...allBookings, res.data.data]);
                setIsBookingFormOpen(!isBookingFormOpen);
                await toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                drawerOnClose();
              })
              .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }
        } else {
        }
      })
      .catch(async (err) => {
        console.log(err);
        const errName = err.path;
        const errMsg = err.message;
        const errs = {
          title: "",
          description: "",
          startDate: "",
          start: "",
          end: "",
          rrule: "",
          endDate: "",
          memberDetails: "",
          participantsDetails: "",
          bookedBy: "",
          meetingLink: "",
          commentForFrontdesk: "",
          pName: "",
          pEmail: "",
        };
        errs[errName] = errMsg;
        setErrors(() => errs);
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    e.preventDefault();
  };

  const handleDateDayLater = () => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  let filterTimeout;
  const handleSubmit = (e) => {
    // Generate some delay to prevent double clicking submit button
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      onSubmit(e);
    }, 500);
  };

  //======== Recurrence States ========
  const handleChange = (event) => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    if (event.target.value === 3) {
      const tempdate = new Date();
      const tempnewDate = addWeeks(tempdate, 1);
      setMain({
        ...main,
        rrule: { type: event.target.value, days: [], exclude: [] },
        endDate: tempnewDate,
      });
    } else if (event.target.value === 2) {
      setMain({
        ...main,
        rrule: { type: event.target.value, days: [], exclude: [] },
        endDate: date,
      });
    } else {
      setMain({
        ...main,
        rrule: { type: event.target.value, days: [], exclude: [] },
      });
    }
  };

  //Recurrence Days
  const recurrenceType = ["", "Once", "Daily", "Weekly"];
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  //======== Participants ========
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const handleInputAdd = () => {
    if (name.trim() !== "" && email.trim() !== "" && /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(email.trim())) {
      setName("");
      setEmail("");
      setMain({
        ...main,
        participantsDetails: [...(main.participantsDetails || []), { name, email }],
      });
    } else {
      toast.error("Please enter valid participant details");
    }
  };
  const handleDelete = (index) => {
    const updatedValues = [...main.participantsDetails];
    updatedValues.splice(index, 1);
    setMain({ ...main, participantsDetails: updatedValues });
  };

  //======== Reset states on closing form ========
  const drawerOnClose = () => {
    setCreatingEvent();
    setUpdatingEvent();
    setMain({
      title: "",
      description: "",
      rrule: { type: 1, days: [], exclude: [] },
      isActive: true,
    });
    setIsParticipants(false);
    setIsForSomeone(false);
    setViewOnlyForm(false);
    setErrors({
      title: "",
      description: "",
      startDate: "",
      start: "",
      end: "",
      rrule: "",
      endDate: "",
      memberDetails: "",
      participantsDetails: "",
      bookedBy: "",
      meetingLink: "",
      commentForFrontdesk: "",
      pName: "",
      pEmail: "",
    });
  };

  // Create Color for Avatar
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) hash = string?.charCodeAt(i) + ((hash << 5) - hash);

    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };
  // Create Avatar content
  const stringAvatar = (name) => {
    return {
      sx: {
        fontSize: "14px",
        height: "30px",
        width: "30px",
        bgcolor: stringToColor(name),
      },
      children: `${name?.split(" ")[0].slice(0, 1)}${name?.split(" ").at(-1).slice(0, 1)}`,
    };
  };

  const handleLaterTime = (val) => {
    const dtime = new Date(val);
    if (dtime?.getTime() !== new Date(main?.start)?.getTime()) {
      setMain({
        ...main,
        end: new Date(val),
      });
    } else {
      dtime?.setMinutes(dtime?.getMinutes() + 30);
      setMain({
        ...main,
        end: new Date(dtime),
      });
    }
  };

  // Search members
  useEffect(() => {
    const searchMembers = (value) => {
      if (value.length > 2) {
        setLoadingAuto(true);
        axios
          .get(`${process.env.REACT_APP_API_URL}/searchUser?searchText=${value}`)
          .then((response) => {
            setSearchedUsers(() => response.data.data);
            setLoadingAuto(false);
          })
          .catch((error) => {
            setSearchedUsers(() => []);
            setLoadingAuto(false);
          });
      } else {
        setSearchedUsers(() => []);
        setLoadingAuto(false);
      }
    };
    if (searchUserText) {
      searchMembers(searchUserText);
    } else searchMembers(searchForSomeone);
  }, [searchUserText, searchForSomeone]);

  const editRights =
    (currentUser?.username === main?.organizer?.username || currentUser?.username === main?.bookedBy?.username) &&
    viewOnlyForm;
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isBookingFormOpen}
      onClose={() => {
        setIsBookingFormOpen(false);
        drawerOnClose();
      }}
      PaperProps={{
        sx: {
          width: editRights ? "80%" : "35%",
        },
      }}
    >
      <Container maxWidth="xl" className="p-0">
        <div className=" d-flex h-100">
          {editRights ? (
            <>
              {/* Form Elements */}
              <div className="col-7 grid row-gap-5 ms-2 me-4 h-100">
                <Card
                  className="p-3"
                  variant="standard"
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 0.5 },
                    justifyContent: "center",
                    padding: 5,
                    alignItems: "center",
                  }}
                  noValidate
                  autoComplete="on"
                >
                  {/* Head */}
                  <div className="d-flex justify-content-between ">
                    <h4>Conference Room Booking Form</h4>
                  </div>

                  {/* Meeting Details */}
                  <div className="mt-3">
                    <h6 className="mb-0 ">Meeting Details</h6>
                    <TextField
                      required
                      fullWidth
                      label="Title"
                      name="title"
                      variant="standard"
                      value={main.title}
                      inputProps={{
                        maxLength: 30,
                      }}
                      onChange={(e) => {
                        onChange(e);
                        if (Object.keys(errors).includes(e.target.name) && errors[e.target.name] !== "") {
                          setErrors({ ...errors, title: "" });
                        }
                      }}
                      onBlur={() => {
                        if (main.title === "") {
                          setErrors({ ...errors, title: "Title is required" });
                        } else {
                          setErrors({ ...errors, title: "" });
                        }
                      }}
                      error={errors?.title !== ""}
                      helperText={errors?.title}
                    />
                    <TextField
                      className="mt-4"
                      multiline
                      fullWidth
                      label="Description"
                      name="description"
                      variant="standard"
                      inputProps={{
                        maxLength: 200,
                      }}
                      value={main.description}
                      onChange={onChange}
                      error={errors.description !== ""}
                      helperText={errors.description}
                    />
                  </div>

                  {/* Date and Time */}
                  <div className="mt-4 d-flex justify-content-between">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + 45)}
                        className="col-3 g-0"
                        name="startDate"
                        label="Date"
                        value={main?.startDate}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setMain({
                            ...main,
                            startDate: new Date(new Date(newValue).toDateString()),
                            endDate: handleLaterDate(newValue),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="standard"
                            error={errors.startDate !== ""}
                            helperText={errors.startDate}
                            style={{ caretColor: "transparent" }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        )}
                      />

                      <TimePicker
                        minTime={minTime}
                        maxTime={maxTime}
                        className="col-3"
                        label="Start Time"
                        name="start"
                        value={main.start}
                        onChange={(newValue) => {
                          const endTime = new Date(newValue);
                          setMain({
                            ...main,
                            start: new Date(newValue),
                            end: new Date(endTime.setMinutes(endTime.getMinutes() + 30)),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="standard"
                            error={errors.start !== ""}
                            helperText={errors.start}
                            style={{ caretColor: "transparent" }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        )}
                      />

                      <TimePicker
                        minTime={minTime}
                        maxTime={maxTime}
                        className="col-3"
                        label="End Time"
                        name="endTime"
                        value={main.end}
                        onChange={(newValue) => {
                          handleLaterTime(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="standard"
                            error={errors.end !== ""}
                            helperText={errors.end}
                            style={{ caretColor: "transparent" }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* Recurance */}
                  <div className="mt-4 d-flex justify-content-between gap-3">
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="recurrence-label">Recurrence-Type</InputLabel>
                      <Select
                        labelId="recurrence-label"
                        id="recurrence"
                        value={main?.rrule?.type}
                        name="recurrence"
                        label="Recurrence-Type"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.rrule !== ""}
                      >
                        <MenuItem value={1}>Once</MenuItem>
                        <MenuItem value={2}>Daily</MenuItem>
                        <MenuItem value={3}>Weekly</MenuItem>
                      </Select>
                    </FormControl>
                    {main.rrule.type !== 1 && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="col-4"
                          minDate={handleDateDayLater()}
                          maxDate={new Date().setDate(new Date().getDate() + 45)}
                          label="End By"
                          value={main?.endDate}
                          name="endDate"
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setMain({
                              ...main,
                              endDate: new Date(new Date(newValue).toDateString()),
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              variant="standard"
                              error={errors.endDate !== ""}
                              helperText={errors.endDate}
                              style={{ caretColor: "transparent" }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  </div>

                  {main.rrule.type === 3 && (
                    <div>
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel className="mt-2 mb-0" sx={{ fontSize: "0.8rem" }} component="legend">
                          Select Days
                        </FormLabel>
                        <StyledToggleButtonGroup
                          size="small"
                          value={main.rrule.days}
                          onChange={(e, newSelectedDays) =>
                            setMain({
                              ...main,
                              rrule: {
                                type: 3,
                                days: newSelectedDays,
                                exclude: [],
                              },
                            })
                          }
                          aria-label="days selection"
                          style={{ display: "flex" }}
                        >
                          {days.map((day) => (
                            <ToggleButton value={day} key={day} aria-label={day}>
                              {day}
                            </ToggleButton>
                          ))}
                        </StyledToggleButtonGroup>
                      </FormControl>
                    </div>
                  )}

                  {/* Member Details */}
                  <div className="mt-4">
                    <h6 className="mb-0 ">Member Details</h6>
                    <Autocomplete
                      id="1"
                      multiple
                      fullWidth
                      loading={loadingAuto && searchUserText}
                      loadingText="Searching..."
                      noOptionsText={searchUserText.length < 3 ? "Keep typing to search..." : "No members found."}
                      onBlur={() => {
                        setSearchUserText("");
                        setLoadingAuto(false);
                      }}
                      onClose={() => {
                        setSearchUserText("");
                        setSearchedUsers([]);
                        setLoadingAuto(false);
                      }}
                      options={searchedUsers ?? []}
                      inputValue={searchUserText}
                      onInputChange={(e, value) => setSearchUserText(value)} // autoHighlight
                      onSelect={() => setSearchedUsers([])}
                      getOptionLabel={(option) => (option ? option.userName : "")}
                      defaultValue={main?.memberDetails?.map((user) => user) ?? []}
                      onChange={(e, value) => {
                        setMain({
                          ...main,
                          memberDetails: value,
                        });
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          <Avatar {...stringAvatar(option.userName.trim())} />
                          <label className="ms-1">{option.userName + " - " + option.email}</label>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Choose members"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingAuto && searchUserText && <CircularProgress className="text-muted" size={20} />}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>

                  {/* Participants Details */}
                  <div className="mt-4">
                    <FormLabel>Is anyone attending apart from Gateway Employees ?</FormLabel>
                    <Switch
                      checked={isParticipants}
                      onChange={() => {
                        setIsParticipants(!isParticipants);
                        if (isParticipants === true) {
                          setIsParticipants(false);
                          const newMain = { ...main };
                          delete newMain.participantsDetails;
                          setMain(newMain);
                          const newErrors = errors;
                          newErrors.pName = "";
                          newErrors.pEmail = "";
                          setErrors(newErrors);
                        }
                      }}
                    />
                    {isParticipants && (
                      <div className="mt-4">
                        <h6 className="mb-0">Attendee Details</h6>
                        <div className="d-flex justify-content-between gap-1">
                          <TextField
                            fullWidth
                            label="Name"
                            variant="standard"
                            value={name}
                            name="pName"
                            onChange={(e) => {
                              setName(e.target.value);
                              if (Object.keys(errors).includes(e.target.name) && errors[e.target.name] !== "") {
                                setErrors({ ...errors, pName: "" });
                              }
                            }}
                            error={errors.pName !== ""}
                            helperText={errors.pName}
                          />
                          <TextField
                            fullWidth
                            label="Email"
                            variant="standard"
                            value={email}
                            name="pEmail"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (Object.keys(errors).includes(e.target.name) && errors[e.target.name] !== "") {
                                let res = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(e.target.value);
                                if (res) {
                                  setErrors({ ...errors, pEmail: "" });
                                } else
                                  setErrors({
                                    ...errors,
                                    pEmail: "Email address is not valid",
                                  });
                              }
                            }}
                            error={errors.pEmail !== ""}
                            helperText={errors.pEmail}
                          />
                          <IconButton className="mt-2 " onClick={handleInputAdd}>
                            <Add />
                          </IconButton>
                        </div>
                        {Object.keys(main).includes("participantsDetails") &&
                          main.participantsDetails.map((value, index) => (
                            <Chip
                              className="mt-2"
                              key={value._id}
                              label={`${value.name} (${value.email})`}
                              onDelete={() => handleDelete(index)}
                              style={{ margin: "2px", height: "30px" }}
                            />
                          ))}
                      </div>
                    )}
                  </div>

                  {/* Other Details */}
                  <div className="mt-4">
                    <h6>Other Details</h6>
                    <div className="d-flex flex-column justify-content-between gap-3">
                      {/* // QA 1 */}
                      <div className="d-flex align-items-start gap-3">
                        <FormLabel className="col-3 mt-2">Booking for someone ?</FormLabel>
                        <Switch
                          checked={isForSomeone || Boolean(main?.bookedBy)}
                          onChange={() => {
                            setIsForSomeone(!isForSomeone);
                            if (isForSomeone === true) {
                              setIsForSomeone(false);
                              delete main.bookedBy;
                              setMain({
                                ...main,
                                organizer: currentUser,
                              });
                            }
                          }}
                        />
                        {(isForSomeone || Boolean(main?.bookedBy)) && (
                          <Autocomplete
                            id="2"
                            className="m-0"
                            fullWidth
                            autoHighlight
                            loading={loadingAuto && searchForSomeone}
                            loadingText="Searching..."
                            noOptionsText={
                              searchForSomeone.length < 3 ? "Keep typing to search..." : "No members found."
                            }
                            onBlur={() => {
                              setSearchForSomeone("");
                              setLoadingAuto(false);
                            }}
                            onClose={() => {
                              setSearchForSomeone("");
                              setSearchedUsers([]);
                              setLoadingAuto(false);
                            }}
                            options={searchedUsers ?? []}
                            inputValue={searchForSomeone}
                            onInputChange={(e, value) => setSearchForSomeone(value)} // autoHighlight
                            onSelect={() => setSearchedUsers([])}
                            getOptionLabel={(option) => option.userName}
                            value={main.bookedBy}
                            onChange={(e, value) => {
                              if (!value) {
                                delete main.bookedBy;
                                setMain({
                                  ...main,
                                  organizer: currentUser,
                                });
                              } else {
                                setMain({
                                  ...main,
                                  organizer: value,
                                  bookedBy: currentUser,
                                });
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                <Avatar {...stringAvatar(option.userName.trim())} />
                                <label className="ms-1">{option.userName + " - " + option.email}</label>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="m-0"
                                variant="standard"
                                label="For whom ?"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingAuto && searchForSomeone && (
                                        <CircularProgress className="text-muted" size={20} />
                                      )}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      </div>

                      {/* // QA 3 */}
                      <div className=" align-items-start gap-3">
                        <FormLabel className=" mt-2">Message to front desk</FormLabel>
                        <TextField
                          rows={4}
                          className="flex-fill mt-4"
                          placeholder={`eg.  1. Pickup client from hotel at 10:00 AM
	2. Arrange breakfast & lunch for client
	3. Book a restaurant for the dinner
	   etc…`}
                          variant="standard"
                          name="commentForFrontdesk"
                          value={main?.commentForFrontdesk}
                          multiline
                          fullWidth
                          onChange={(e) => {
                            setMain({
                              ...main,
                              [e.target.name]: e.target.value,
                            });
                            if (e.target.value === "") {
                              delete main.commentForFrontdesk;
                              setMain({ ...main });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col m-0">
                    <Box
                      component="span"
                      className="col p-2"
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                      gap={2}
                    >
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        onClick={() => {
                          setIsBookingFormOpen(false);
                          drawerOnClose();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" size="medium" color="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </Box>
                  </div>
                </Card>
              </div>

              <div className="vr my-3 me-2"></div>
            </>
          ) : (
            ""
          )}
          {/* Booking Summary */}
          <div className="ms-1 d-flex w-100   ">
            <Card
              className="p-3 w-100"
              variant="standard"
              sx={{
                justifyContent: "center",
                padding: 5,
                alignItems: "center",
              }}
            >
              <h4 className="d-flex align-items-center justify-content-between">
                <NoteAltOutlined fontSize="large" /> <label className="flex-fill ms-2">Booking Summary</label>
                {!viewOnlyForm && (
                  <IconButton
                    onClick={() => {
                      setIsBookingFormOpen(!isBookingFormOpen);
                      drawerOnClose();
                    }}
                    className="justify-self-end"
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </h4>
              <div className="table-borderless table mt-4" style={{ fontFamily: "inherit", fontSize: "1rem" }}>
                <table className="align-top table-condensed w-100 m-0">
                  <tbody>
                    {/* Meeting Details */}
                    <>
                      <tr>
                        <td colSpan={3}>
                          <h6>Meeting Details</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="p-0">
                          <hr className="mt-0" />
                        </td>
                      </tr>

                      {Object.keys(main).includes("title") && main.title !== "" && (
                        <tr>
                          <td width="29%">Title</td>
                          <td>:</td>
                          <td style={{ wordBreak: "break-word" }}>{main.title ? main.title : ""}</td>
                        </tr>
                      )}

                      {Object.keys(main).includes("description") && main.description !== "" && (
                        <tr>
                          <td>Description</td>
                          <td>:</td>
                          <td style={{ wordBreak: "break-word" }}>{main.description ?? ""}</td>
                        </tr>
                      )}

                      {Object.keys(main).includes("organizer") &&
                        currentUser?.username !== main?.organizer?.username && (
                          <tr>
                            <td>Organizer</td>
                            <td>:</td>
                            <td>{main?.organizer && main?.organizer?.userName}</td>
                          </tr>
                        )}

                      <tr>
                        <td height={10} colSpan={3}></td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>:</td>
                        <td>
                          {main.resourceId &&
                            allResources?.length &&
                            allResources?.filter((resource) => {
                              return resource._id === main.resourceId;
                            })[0]?.roomName}
                        </td>
                      </tr>

                      <tr>
                        <td>Date</td>
                        <td>:</td>
                        <td>{main.startDate && new Date(main.startDate).toDateString()}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>:</td>
                        <td>
                          {main.start &&
                            new Date(main.start).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                          {main.end &&
                            " - " +
                              new Date(main.end).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                        </td>
                      </tr>
                    </>

                    {/* Recurrence Details */}
                    <>
                      {main.rrule.type !== 1 && (
                        <>
                          <tr>
                            <td height={30} colSpan={3}></td>
                          </tr>

                          <tr>
                            <td colSpan={3}>
                              <h6>Recurrence Details</h6>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={3} className="p-0">
                              <hr className="mt-0" />
                            </td>
                          </tr>

                          <tr>
                            <td>Frequency</td>
                            <td>:</td>
                            <td>{recurrenceType[main.rrule.type]}</td>
                          </tr>

                          <tr>
                            <td>End By</td>
                            <td>:</td>
                            <td>{main.endDate && new Date(main.endDate).toDateString()}</td>
                          </tr>

                          {main.rrule.type === 3 && (
                            <tr>
                              <td>Days</td>
                              <td>:</td>
                              <td>
                                {main.rrule.days.map(
                                  (day, index) => ` ${day.slice(0, 3)}${index < main.rrule.days.length - 1 ? ", " : ""}`
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </>

                    {/* Member Details */}
                    <>
                      {Object.keys(main).includes("memberDetails") && main.memberDetails.length !== 0 && (
                        <>
                          <tr>
                            <td height={30} colSpan={3}></td>
                          </tr>

                          <tr>
                            <td colSpan={3}>
                              <h6>Members</h6>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={3} className="p-0">
                              <hr className="mt-0" />
                            </td>
                          </tr>

                          {main.memberDetails.map((p) => (
                            <tr key={p._id}>
                              <td>{p.userName}</td>
                              <td></td>
                              <td>{p.email}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </>

                    {/* Participant Details */}
                    <>
                      {Object.keys(main).includes("participantsDetails") && main.participantsDetails.length !== 0 && (
                        <>
                          <tr>
                            <td height={30} colSpan={3}></td>
                          </tr>

                          <tr>
                            <td colSpan={3}>
                              <h6>Participants</h6>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={3} className="p-0">
                              <hr className="mt-0" />
                            </td>
                          </tr>

                          {main.participantsDetails.map((p) => (
                            <tr key={p._id}>
                              <td>{p.name}</td>
                              <td></td>
                              <td>{p.email}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </>

                    {/* Other Details */}
                    <>
                      {(Object.keys(main).includes("bookedBy" || "commentForFrontdesk") ||
                        Object.keys(main).includes("commentForFrontdesk")) && (
                        <>
                          <tr>
                            <td height={30} colSpan={3}></td>
                          </tr>

                          <tr>
                            <td colSpan={3}>
                              <h6>Other Details</h6>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={3} className="p-0">
                              <hr className="mt-0" />
                            </td>
                          </tr>

                          {Object.keys(main).includes("bookedBy") && main.bookedBy !== null && (
                            <>
                              <tr>
                                <td>Booked by</td>
                                <td>:</td>
                                <td>{main.bookedBy.userName}</td>
                              </tr>
                              <tr>
                                <td height={10} colSpan={3}></td>
                              </tr>
                            </>
                          )}

                          {Object.keys(main).includes("commentForFrontdesk") && main.commentForFrontdesk !== "" && (
                            <tr>
                              <td>Comment for frontdesk</td>
                              <td>:</td>
                              <td>
                                {main.commentForFrontdesk.split("\n").map((line, index) => (
                                  <React.Fragment key={line}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </>
                  </tbody>
                </table>
              </div>
              {!viewOnlyForm && bookingHistory?.map((e) => e?.organizer?._id).includes(main?.organizer?._id) && (
                <div className="my-4 gap-2 d-flex  ">
                  {/* Rebook */}
                  {filterCompletedEvents(bookingHistory)
                    ?.map((e) => e?._id)
                    .includes(main?._id) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{ fontSize: 12 }}
                      onClick={() => {
                        // Gives Current Time with Date 01/01/19770
                        const startTime = new Date();
                        startTime.setFullYear(1970);
                        startTime.setMonth(0);
                        startTime.setDate(1);
                        const endTime = new Date(startTime);
                        // Event to be Reboked
                        const eventToRebook = main;
                        eventToRebook.startDate = new Date(new Date().toDateString());
                        eventToRebook.endDate = new Date(new Date().toDateString());
                        eventToRebook.start = startTime;
                        eventToRebook.end = endTime.setMinutes(endTime.getMinutes() + 30);
                        setCreatingEvent(eventToRebook);
                        setViewOnlyForm(true);
                      }}
                    >
                      <EventRepeatIcon sx={{ fontSize: 15 }} />
                      &nbsp;Rebook
                    </Button>
                  )}

                  {/* Cancel / Update */}
                  {filterUpcomingEvents(bookingHistory)
                    ?.map((e) => e?._id)
                    .includes(main?._id) && (
                    <>
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={() => setOpenCancelConfirm(`${main?._id}$${main?.title}$${main?.rrule?.type}`)}
                        sx={{ fontSize: 12 }}
                      >
                        <AutoDelete sx={{ fontSize: 15 }} />
                        &nbsp;Cancel
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setViewOnlyForm(true);
                          setUpdatingEvent(main);
                        }}
                        sx={{ fontSize: 12 }}
                      >
                        <NoteAlt sx={{ fontSize: 15 }} />
                        &nbsp;Update
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </Container>
    </Drawer>
  );
};
export default Form;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
